



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JwlButton from '@/components/JwlButton.vue';

const LmsContactOverlay = () => import('./LmsContactOverlay.vue');

@Component({
  components: {
    JwlButton,
    LmsContactOverlay,
  },
})
export default class LmsContactInitiator extends Vue {
  @Prop(Number)
  contactUser!: number;

  @Prop(Number)
  contactClassroom!: number;

  @Prop(Number)
  courseId!: number;

  @Prop(Number)
  otherClassroom!: number;

  @Prop(String)
  targetRole!: string;

  overlayOpen = false

  openOverlay (): void {
    this.overlayOpen = true;
  }

  closeOverlay (): void {
    this.overlayOpen = false;
  }

  get buttonClasses (): Record<string, boolean> {
    return {
      'jwl-gradebook-contact__button--medium': !this.contactUser,
    };
  }
}
